import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Descriptor } from '../../../../models/Descriptor';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import ClientFormService from '../../../../services/ClientFormService';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { CollapsedForm, CollapsedFormType } from '../../../shared/collapsed-form/CollapsedForm';
import ActionPreview from '../../ActionPreview';
import { ChildFormRenderType } from './ChildFormRenderType';
import { FormRendererType, useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { ChildFormListActionProps } from './ChildFormListAction';
import { useFormAction } from '../../../../contexts/FormActionContext';

const ChildFormListPreview: FC<ChildFormListActionProps> = (props) => {
  const { response, data } = props;
  const [forms, setForms] = useState<Record<string, Descriptor>>({});
  const { question, previewQuestion, renderType } = data;
  const { renderMinimal, isDisplayedInline, type } = useFormRendererInfo();

  const client = useRecoilValue(currentClientAtom);

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const { currentAction } = useFormAction();

  useEffect(() => {
    if (!renderMinimal) {
      return;
    }

    for (const form of response || []) {
      ClientFormService.getFormInfo(form.id).then((res) => {
        setForms((prev) => ({ ...prev, [form.id]: res.data }));
      });
    }
  }, [renderMinimal, response]);

  if (renderMinimal) {
    return (
      <>
        <ActionPreview question={title} data-cy="child-form-preview" />
        {(response || []).map((subForm, i) => (
          <Link
            key={subForm.id}
            to={`/clients/${client?.id}/forms/${subForm.id}`}
            className={`block underline ${i === (response?.length ?? 0) - 1 ? 'mb-4' : ''}`}
          >
            {forms[subForm.id]
              ? LanguageUtils.getTranslation('description', forms[subForm.id].translations) ||
                LanguageUtils.getTranslation('title', forms[subForm.id].translations)
              : '...'}
          </Link>
        ))}
        {(!response || response.length === 0) && <div>-</div>}
      </>
    );
  }

  return (
    <div data-cy="child-form-preview">
      <ActionPreview
        question={title}
        data-cy="child-form-preview"
        answer={(response || []).map((subForm, i) => {
          return (
            <CollapsedForm
              formType={CollapsedFormType.Subform}
              formId={subForm.id}
              key={subForm.id}
              showInline={(!isDisplayedInline && renderType === ChildFormRenderType.Inline) || type === FormRendererType.Public}
              expanded={type === FormRendererType.Public}
              canEdit={false}
              data-cy={`child-form-${subForm.id}`}
              previewMode
              actionNumber={`${currentAction.number}${i + 1}.`}
            />
          );
        })}
      />
    </div>
  );
};

export default ChildFormListPreview;
